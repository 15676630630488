<template>
  <div>
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1 mt-8 ">

        <SignIn></SignIn>


      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2">

        <LiveSearch></LiveSearch>

        <LastPosts></LastPosts>

        <RelatedPosts></RelatedPosts>

      </div>
    </v-container>


  </div>
</template>

<script>
import SignIn from "./SignIn";
import RelatedPosts from "../components/RelatedPosts";
import LastPosts from "../components/LastPosts";
import LiveSearch from "../components/LiveSearch";
export default {
  name: 'App',
  components : {
    SignIn,
    RelatedPosts,
    LastPosts,
    LiveSearch
  },
  data() {
    return {
      //
    }
  },
  metaInfo() {
    return {
      title:'وارد شوید',
      link: [{rel: 'canonical', href: this.$store.state.url+'/signin'}]
    }
  },
};
</script>
